import React from 'react';

import { useTranslation } from 'react-i18next';
import Layout from 'src/components/layout/layout';
import SEO from 'src/components/seo';

const NotFoundPage = () => {
  const { t } = useTranslation();
  // This is a workaround for the 404 Page rendering before the actual content on route rewrites
  const isBrowser = typeof window !== 'undefined';
  return (
    <Layout>
      {isBrowser && (
        <>
          <SEO title="404: Not found" />
          <h1>{t('404 title')}</h1>
          <p>{t('404 message')}</p>
        </>
      )}
    </Layout>
  );
};

export default NotFoundPage;
